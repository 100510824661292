import 'popper.js'
import { Alert, Toast, Tooltip } from 'bootstrap' // eslint-disable-line no-unused-vars
import tippy from 'tippy.js' // eslint-disable-line no-unused-vars
import 'alpinejs'
import Chart from 'chart.js'

console.log('public')

Array.from(document.querySelectorAll('.toast')).forEach(toastNode => new Toast(toastNode).show())

var x = document.getElementsByClassName('update_btn')

if (x.addEventListener) {
  x.addEventListener('click', myFunction)
} else if (x.attachEvent) {
  x.attachEvent('onclick', myFunction)
}

function myFunction () {
  alert('Hello World!')
}

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl)
})

// tippy('h1', {
//   content: 'Tippy test!',
//   trigger: 'click',
//   placement: 'left'
// })

// var exampleEl = document.getElementById('cancel')
// if (exampleEl) {
//   const tooltip = new Tooltip(exampleEl, {}) // eslint-disable-line no-unused-vars
// }
